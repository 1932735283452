import Header from "./Header";
import Footer from "./Footer";
import ImageStack from "./ImageStack";
import { useRef, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Formitable from "../components/Formitable";

const Page = (props) => {
  const innerHeight = useRef();
  const outerHeight = useRef();
  const innerWidth = useRef();
  const outerWidth = useRef();

  const [gridHeight, setGridHeight] = useState(0);
  const [gridWidth, setGridWidth] = useState([]);
  const [dimensions, setDimensions] = useState(0, 0);

  const resizeWindow = () => {
    setDimensions(window.innerWidth, window.innerHeight);
    resizeGrid();
  };
  const f = (n) => {
    return (Math.floor(n / 54) + (n % 1 === 0 ? 0 : 1)) * 54;
  };

  const fw = (n) => {
    return (Math.floor(n / 54) + (n % 54 ? 1 : 0)) * 52;
  };

  const resizeGrid = () => {
    const h = innerHeight.current?.children[0].clientHeight + 64;
    const height = f(h);
    const blogElement = document.getElementById("blog-container");

    if (props.shortPage) {
      setGridHeight(height - 108);
      blogElement.style.height = `${height + 100}px`;
    } else {
      setGridHeight(height);
      blogElement.style.height = `${height + 100}px`;
    }

    const w = innerWidth.current?.clientWidth;
    const wi = outerWidth.current?.clientWidth;
    let width;
    let widthI;

    if (window.innerWidth < 760) {
      width = fw(w);
      widthI = fw(wi);
    } else {
      width = f(w);
      widthI = f(wi);
    }

    setGridWidth([width, widthI]);
  };

  useEffect(() => {
    outerHeight.current.style.height = gridHeight;
    if (window.innerWidth > 1200) {
      innerHeight.current.style.height = gridHeight - 2;
    } else {
      innerHeight.current.style.height = gridHeight;
    }

    if (innerWidth.current !== undefined && outerWidth.current !== undefined) {
      innerWidth.current.style.width = gridWidth[0];
      outerWidth.current.style.width = gridWidth[1];
    }
  }, [gridHeight, gridWidth]);

  const fadeInImage = () => {
    setTimeout(() => {
      const imgOpacityElement = document.querySelector(".img-opacity");
      if (imgOpacityElement && imgOpacityElement.children.length > 0) {
        imgOpacityElement.children[0].classList.remove("lazyloading");
      }
    }, 200);
  };

  useEffect(() => {
    resizeGrid();
  }, [props.content]);
  useEffect(() => {
    fadeInImage();
  }, []);
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <div>
      <Header
        langNL={props.langNL}
        changeLang={props.changeLang}
        resizeGrid={resizeGrid}
        pages={props.header}
      />
      <div className="grid__container">
        <div className="grid__wrapper">
          <div className="grid grid__top grid-home"></div>
        </div>
      </div>
      <section className="info__container" id="blog-container">
        <div className="info__container-header page__info">
          <div className="page__header">
            <h1 className="page__header-title page__info">
              <span className="ff">{props.headerTitle}</span>
              {props.headerTitle === "Team DS" && (
                <>
                  <div
                    className="navigation__trigger js-navigation-trigger ff teams__navigation-trigger"
                    style={{ marginTop: "19px", marginRight: "-12px" }}
                    onClick={props.toggleMenu}
                  ></div>
                </>
              )}
            </h1>

            <div className="page__header-pattern dots__pattern page__info"></div>
            {props.routeTitle && (
              <div
                className="page__header-extra-title calc-width page__header-extra-title--2"
                ref={innerWidth}
              >
                <Link
                  to={props.routeLink}
                  className="page__header-extra-title-link ff"
                  title=""
                >
                  {props.routeTitle}
                </Link>
              </div>
            )}

            {props.extraTitle && (
              <div
                className="page__header-extra-title calc-width page__header-extra-title--2"
                ref={innerWidth}
              >
                <a
                  target="_blank"
                  rel="noreferrer"
                  href={props.extraTitleLink}
                  className="page__header-extra-title-link ff"
                  title=""
                >
                  {props.extraTitle}
                </a>
              </div>
            )}

            {props.extraTitleOne && (
              <div
                className="page__header-extra-title calc-width"
                ref={outerWidth}
                style={{ width: gridWidth[1] }}
              >
                {props.extraTitleOneLink ? (
                  <a
                    href={props.extraTitleOneLink}
                    target="_blank"
                    rel="noreferrer"
                    className="page__header-extra-title-link ff"
                    title=""
                  >
                    {props.extraTitleOne}
                  </a>
                ) : (
                  <h4>{props.extraTitleOne}</h4>
                )}
              </div>
            )}
          </div>

          <div className="info__grid-title">
            <div className="ff">{props.description}</div>
          </div>
        </div>

        {props.image && (
          <div className="page__container-wrapper">
            <div className="info__grid-image img-opacity">
              <ImageStack image={props.image} />
            </div>
          </div>
        )}

        {props.outsideContent ? (
          <div
            className="info__container-content-grid page__blog"
            ref={outerHeight}
          >
            <div
              className="info__container-content calc-height page__info"
              ref={innerHeight}
            >
              <div className="info__content-description">
                <div className="info__content-description-info ff">
                  {props.content}
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div
            className="info__container-content-grid page__info"
            ref={outerHeight}
          >
            <div
              className="info__container-content calc-height page__info"
              ref={innerHeight}
            >
              <div className="info__content-description">
                <div className="info__content-description-info ff">
                  {props.content}
                </div>
              </div>
            </div>
          </div>
        )}

        {props.outsideContent && props.outsideContent}
      </section>

      <Footer isNL={props.langNL} pages={props.footer} />
      <div id="outdated">
        <h6>Your browser is out-of-date!</h6>
        <p>
          Update your browser to view this website correctly.
          <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/">
            {" "}
            Update my browser now
          </a>
        </p>
      </div>
      <Formitable isActive={props.formitable} />
    </div>
  );
};

export default Page;

import React, { useEffect } from "react";

const Formitable = ({ isActive }) => {
  useEffect(() => {
    // Creating script tag
    const script = document.createElement("script");
    script.id = "formitable-sdk";
    script.src = "https://cdn.formitable.com/sdk/v1/ft.sdk.min.js";
    script.onload = () => {
      window.FT && window.FT.load("Analytics");
    };

    if (!document.getElementById("formitable-sdk") && isActive) {
      document.body.appendChild(script);
    }
  }, []); // Empty dependency array means this useEffect runs once when the component mounts
  const classes = "ft-widget-b2";
  const widget = window.FT;
  if (!isActive) {
    if (widget) {
      widget.widgets.current.element.style.display = "none";
      widget.widgets.current.element.className =
        widget.widgets.current.element.className.replace(classes, "");
    }
  } else {
    if (widget && !widget.widgets.current.element.className.includes(classes)) {
      widget.widgets.current.element.className =
        widget.widgets.current.element.className + " " + classes;
    }
  }
  return (
    <div
      className={classes}
      data-restaurant="f6a3b51d"
      data-open="1500"
      data-open-mobile="false"
      data-color="#121111"
      data-language="auto"
      data-tag="Website"
      data-toolbar="true"
      data-toolbar-mobile="true"
    ></div>
  );
};

export default Formitable;

import Page from "../components/Page";
import ImageStack from "../components/ImageStack";
import useFetchContent from "../hooks/useFetchContent";
import config from "../config";

const Art = (props) => {
  const { data, loading, error } = useFetchContent("/pages/art");
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

  const headerTitle = data && data.title ? data.title : "Art DS";
  const description = data && data.grid_title ? data.grid_title : "";
  const content = data && data.content ? data.content : "";

  document.title = `${headerTitle} - De School Amsterdam`;

  const outsideContent = (
    <div className="blog-item__container-wrapper page__blog">
      {data &&
        data.art.map((post, index) => (
          <div className="blog-item__container" key={index}>
            <a
              href={
                config.dev
                  ? post.site_url.replace(
                      "https://deschoolamsterdam.nl",
                      config.baseUrl
                    )
                  : post.site_url
              }
            >
              <span className="blog-item__image img-opacity">
                <ImageStack image={post.images ? post.images[0] : null} />
              </span>
              <div className="blog-item__info">
                <div className="blog-item__title ff">
                  <span className="blog-item__title-link">{post.title}</span>
                </div>
                <div className="blog-item__link ff">{post.description}</div>
              </div>
            </a>
          </div>
        ))}
    </div>
  );

  return (
    <Page
      headerTitle={headerTitle}
      description={description}
      content={
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      }
      langNL={props.langNL}
      outsideContent={outsideContent}
      changeLang={props.changeLang}
      header={data?.navigation?.top}
      footer={data?.navigation?.footer}
    />
  );
};

export default Art;

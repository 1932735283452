import { Link } from "react-router-dom";
import pressRelease from "../static/pdf/De School Press Release.pdf";

const Footer = ({ pages }) => {
  let num = 4;
  pages && pages.length > 4 && (num = Math.floor((pages.length - 1) / 2));
  return (
    <footer className="footer__container">
      <div className="footer__row footer__link-row">
        {pages &&
          pages.slice(0, num).map((page, index) => (
            <>
              <span> &nbsp; </span>
              <Link
                to={page.site_url.replace("/pages", "")}
                className="footer__link ff"
                key={index}
                title={page.title}
              >
                {page.title}
              </Link>
            </>
          ))}
        <span> &nbsp; </span>
      </div>
      {pages && pages.length > 4 && (
        <div className="footer__row footer__link-row">
          {pages &&
            pages.slice(num, pages.length).map((page, index) => (
              <>
                <Link
                  to={page.site_url.replace("/pages", "")}
                  className="footer__link ff"
                  key={index}
                  title={page.title}
                >
                  {page.title}
                </Link>
                <span> &nbsp; </span>
              </>
            ))}
        </div>
      )}
    </footer>
  );
};

export default Footer;

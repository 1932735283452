const config = {
  apiUrl:
    process.env.NODE_ENV === "development"
      ? "http://localhost:3001/api"
      : "https://cms.deschoolamsterdam.nl/api",
  dev: process.env.NODE_ENV === "development" ? true : false,
  baseUrl: window.location.origin,
};

export default config;

import Header from "../components/Header";
import Footer from "../components/Footer";
import "../main0.css";
import useFetchContent from "../hooks/useFetchContent";
import config from "../config";

const Home = (props) => {
  const { data, loading, error } = useFetchContent("/pages/colophon");
  document.title = "De School Amsterdam";
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;
  return (
    <div>
      <Header pages={data?.navigation?.top} />
      <div className="grid__container">
        <div className="grid__wrapper">
          <div className="grid grid__top grid-home"></div>
        </div>
      </div>

      <section className="info__container">
        <div className="info__container-header title-page-header page__home">
          <div className="page__header">
            <h1 className="page__header-title">
              <span className="ff">DE SCHOOL</span>
            </h1>
          </div>
        </div>
        <div
          className="info__container-content-grid page__home"
          style={{ height: "0px" }}
        ></div>
      </section>
      <Footer pages={data?.navigation?.footer} />
      <div id="outdated">
        <h6>Your browser is out-of-date!</h6>
        <p>
          Update your browser to view this website correctly.
          <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/">
            {" "}
            Update my browser now
          </a>
        </p>
      </div>

      <div id="fb-root"></div>
    </div>
  );
};

export default Home;

import React from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import { TransitionGroup, CSSTransition } from "react-transition-group";

import { useState } from "react";

import ScrollToTop from "./components/ScrollToTop";

import Home from "./routes/Home";
import Restaurant from "./routes/Restaurant";
import Cafe from "./routes/Cafe";
import Club from "./routes/Club";
import Art from "./routes/Art";
import Contact from "./routes/Contact";
import Colophon from "./routes/Colophon";
import HouseRules from "./routes/HouseRules";
import TermsAndConditions from "./routes/TermsAndConditions";
import CodeOfConduct from "./routes/CodeOfConduct";
import Disclaimer from "./routes/Disclaimer";
import BlogPost from "./routes/BlogPost";
import Jobs from "./routes/Jobs";
import CustomPage from "./routes/CustomPage";

// import Login from './hollanddev/Login';

function App() {
  const [langNL, setLangNL] = useState(false);
  const location = useLocation();

  return (
    <React.StrictMode>
      <ScrollToTop />
      <TransitionGroup component={null}>
        <CSSTransition key={location.key} classNames="fade" timeout={300}>
          <Routes location={location}>
            <Route
              path="/"
              element={
                <Home
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/restaurant"
              element={
                <Restaurant
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/cafe"
              element={
                <Cafe
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/club"
              element={
                <Club
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/programme"
              element={
                <Club
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/art"
              element={
                <Art
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/contact"
              element={
                <Contact
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/jobs"
              element={
                <Jobs
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/colophon"
              element={
                <Colophon
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/house-rules"
              element={
                <HouseRules
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/terms-and-conditions"
              element={
                <TermsAndConditions
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />
            <Route
              path="/disclaimer"
              element={
                <Disclaimer
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route
              path="/code-of-conduct"
              element={
                <CodeOfConduct
                  langNL={langNL}
                  changeLang={(isNL) => {
                    setLangNL(isNL);
                  }}
                />
              }
            />

            <Route path="/art/:postTitle" element={<BlogPost />} />
            <Route path=":slug/*" element={<CustomPage />} />
          </Routes>
        </CSSTransition>
      </TransitionGroup>
    </React.StrictMode>
  );
}

export default App;

const ImageStack = (props) => {
  return (
    <div className={`lazyloading background__container-animation${props.art ? '-art' : ''}`} style={{backgroundImage: "url("+props.image+")"}}>
      {props.children}
    </div>
  );
};



export default ImageStack;

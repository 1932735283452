import Page from "../components/Page";
import useFetchContent from "../hooks/useFetchContent";

const Jobs = (props) => {
  const { data, loading, error } = useFetchContent("/pages/jobs");

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;
  const headerTitle = data && data.title ? data.title : "";
  const description = data && data.grid_title ? data.grid_title : "";
  const content = data && data.content ? data.content : "";
  const image =
    data && data.image && data.image.length > 0 ? data.image[0] : null;

  document.title = `${headerTitle} - De School Amsterdam`;

  return (
    <Page
      headerTitle={headerTitle}
      description={description}
      content={
        <div
          className="content"
          dangerouslySetInnerHTML={{ __html: content }}
        ></div>
      }
      image={image}
      langNL={props.langNL}
      changeLang={props.changeLang}
      header={data?.navigation?.top}
      footer={data?.navigation?.footer}
    />
  );
};

export default Jobs;

import Page from "../components/Page";
import useFetchContent from "../hooks/useFetchContent";
import plattegrond from "../static/pdf/DS_-_PLATTEGROND_2_798.pdf";

const Contact = (props) => {
  const { data, loading, error } = useFetchContent("/pages/contact");

  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

  const headerTitle = data && data.title ? data.title : "";
  const description = data && data.grid_title ? data.grid_title : "";
  const image =
    data && data.images && data.images.length > 0 ? data.images[0] : null;

  document.title = `${headerTitle} - De School Amsterdam`;

  const content = (
    <div
      className="content"
      dangerouslySetInnerHTML={{
        __html: data && data.content ? data.content : "",
      }}
    ></div>
  );

  const extraTitleOne = "Route";
  const extraTitleOneLink = plattegrond;

  return (
    <Page
      headerTitle={headerTitle}
      description={description}
      extraTitleOne={extraTitleOne}
      extraTitleOneLink={extraTitleOneLink}
      content={content}
      image={image}
      langNL={props.langNL}
      changeLang={props.changeLang}
      header={data?.navigation?.top}
      footer={data?.navigation?.footer}
    />
  );
};

export default Contact;

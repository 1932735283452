import Header from "./Header";
import Footer from "./Footer";
import { useRef, useEffect, useState, useCallback } from "react";
import ImageStack from "./ImageStack";

const PageBlog = (props) => {
  const innerHeight = useRef();
  const outerHeight = useRef();
  const innerWidth = useRef();
  const outerWidth = useRef();

  const [gridHeight, setGridHeight] = useState(0);
  const [gridWidth, setGridWidth] = useState([]);
  const [dimensions, setDimensions] = useState(0, 0);
  const [currentImage, setCurrentImage] = useState(
    props.images && props.images.length > 0 ? props.images[0] : null
  );
  const [stopSwitching, setStopSwitching] = useState(false);

  const switchImageRef = useRef();
  const contentRef = useRef(null);

  const resizeWindow = () => {
    setDimensions(window.innerWidth, window.innerHeight);
    resizeGrid();
  };

  const f = (n) => {
    return (Math.floor(n / 54) + (n % 1 ? 1 : 0)) * 54;
  };

  const fw = (n) => {
    return (Math.floor(n / 54) + (n % 54 ? 1 : 0)) * 52;
  };

  const resizeGrid = () => {
    const h = innerHeight.current?.children[0].clientHeight + 94;
    const height = f(h);

    if (props.shortPage) {
      setGridHeight(height - 108);
    } else {
      setGridHeight(height);
    }

    const w = innerWidth.current?.clientWidth;
    const wi = outerWidth.current?.clientWidth;
    let width;
    let widthI;

    if (window.innerWidth < 760) {
      width = fw(w);
      widthI = fw(wi);
    } else {
      width = f(w);
      widthI = f(wi);
    }

    setGridWidth([width, widthI]);
  };

  useEffect(() => {
    outerHeight.current.style.height = gridHeight;
    if (window.innerWidth > 1200) {
      innerHeight.current.style.height = gridHeight - 2;
    } else {
      innerHeight.current.style.height = gridHeight;
    }

    if (innerWidth.current !== undefined && outerWidth.current !== undefined) {
      innerWidth.current.style.width = gridWidth[0];
      outerWidth.current.style.width = gridWidth[1];
    }
  }, [gridHeight, gridWidth]);

  const handleImageSwitch = (direction) => {
    const currentIndex = props.images.indexOf(currentImage);
    let nextIndex;
    if (direction === "next") {
      setStopSwitching(true);
      nextIndex =
        currentIndex === props.images.length - 1 ? 0 : currentIndex + 1;
    } else if (direction === "back") {
      setStopSwitching(true);
      nextIndex =
        currentIndex === 0 ? props.images.length - 1 : currentIndex - 1;
    } else {
      console.error(`Invalid direction: ${direction}`);
      return;
    }
    setCurrentImage(props.images[nextIndex]);
  };

  useEffect(() => {
    const resizeObserver = new ResizeObserver(() => {
      resizeGrid();
    });

    if (contentRef.current) {
      resizeObserver.observe(contentRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [props.content]);
  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  return (
    <>
      <Header resizeGrid={resizeGrid} />
      <div className="grid__container">
        <div className="grid__wrapper">
          <div className="grid grid__top grid-home"></div>
        </div>
      </div>
      <section className="info__container">
        <div className="info__container-header page__blog-item">
          <div className="page__header page__blog-item">
            <h1 className="page__header-title page__blog-item">
              <span className="ff">{props.headerTitle}</span>
            </h1>

            <div className="page__header-pattern-second stairs__pattern page__blog-item"></div>
          </div>

          <div className="grid__header-image page__blog-item">
            {currentImage && (
              <div className="img-opacity">
                <ImageStack image={currentImage} />
              </div>
            )}

            {/* Show navigation buttons if there is more than one image */}
            {props.images && props.images.length > 1 ? (
              <>
                <span
                  onClick={() => handleImageSwitch("back")}
                  className="art__image-go-left"
                >
                  BACK
                </span>
                <span
                  onClick={() => handleImageSwitch("next")}
                  className="art__image-go-right"
                >
                  NEXT
                </span>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
        <div
          className="info__container-content-grid page__blog-item"
          ref={outerHeight}
        >
          <div
            className="info__container-content calc-height page__blog-item"
            ref={innerHeight}
          >
            <div className="info__content-description ff" ref={contentRef}>
              <div className="info__content-description-blog__item ff">
                {props.content}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer isNL={props.langNL} />
      <div id="outdated">
        <h6>Your browser is out-of-date!</h6>
        <p>
          Update your browser to view this website correctly.
          <a id="btnUpdateBrowser" href="http://outdatedbrowser.com/">
            {" "}
            Update my browser now
          </a>
        </p>
      </div>
    </>
  );
};

export default PageBlog;

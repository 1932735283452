import { useParams } from "react-router-dom";
import useFetchContent from "../hooks/useFetchContent";
import PageBlog from "../components/PageBlog";

const BlogPost = (props) => {
  const { postTitle } = useParams();

  const { data, loading, error } = useFetchContent(`/art/${postTitle}`);
  if (loading) return <div>Loading...</div>;
  if (error) return <div>Error loading content.</div>;

  if (!data) return null; // Do not render anything if there's no data

  const headerTitle = data.title || "";
  const description = data.introduction || "";
  const images = data.images;

  document.title = `${headerTitle} - De School Amsterdam`;

  const content = (
    <div
      className="content"
      dangerouslySetInnerHTML={{
        __html: data.content || "",
      }}
    ></div>
  );

  return (
    <PageBlog
      headerTitle={headerTitle}
      description={description}
      content={content}
      images={images}
    />
  );
};

export default BlogPost;
